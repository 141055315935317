html {
  height: 100%;
}

.padding {
  padding: 0 1rem;
}

#body {
  margin-top: 3rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

mt-0 {
  margin-top: 0 !important;
}

img {
  max-width: 100%;
  &.left {
    float: left;
    max-width: 50%;
    margin: 0 1.5rem 1rem 0;
  }
  &.right {
    float: right;
    max-width: 50%;
    margin: 0 0 1rem 1.5rem;
  }
}

#error {
  text-align: center;
  position: relative;
  margin-top: 5rem;

  .icon {
    font-size: 50px;
  }
}
