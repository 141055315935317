// Colors
$dropmenu-bg: #A6A6A8;
$dropmenu-text: $gray-color-dark;
$dropmenu-hover-bg: $primary-color;
$dropmenu-hover-text: $light-color;

// Padding
$dropmenu-horiz-padding: 20px;
$dropmenu-vert-padding: 10px;
$dropmenu-child-padding: 10px;

$dropmenu-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

.dropmenu {

  margin-top: 1rem;

  @include breakpoint(md) {
    display: none;
  }

  li.active {
    > a {
      color: $tertiary-color !important;
      font-weight: 400;
    }
  }

  ul {
    white-space: nowrap;
    margin: 0;
    display: flex;

    li {
      position: relative;
      margin: 0;
      padding: 0;
      cursor: pointer;

      a {
        text-decoration: none;
        color: $dropmenu-text;
        padding: $dropmenu-vert-padding ($dropmenu-horiz-padding + $dropmenu-child-padding) $dropmenu-vert-padding $dropmenu-horiz-padding;
        display: block;
        text-transform: uppercase;


        &:before {
          content: 'T';
          font-family: 'dripicons-v2';
          display: inline-block;
          vertical-align: middle;
          float: right;
          margin-right: - (2 *$dropmenu-child-padding);
        }

        &:only-child {
          background: none;
          padding-right: $dropmenu-horiz-padding;

          &:before {
            content: '';
          }
        }
      }

      &:hover {

        & > a {
          color: $nile-blue-color;
        }

        & > ul {
          display: block;
          visibility: visible;
        }
      }


    }

    ul li a:before {
      content: 'V';
    }

    ul {
      z-index: 100;
      position: absolute;
      top: 100%;
      list-style: none;
      background: $dropmenu-bg;
      box-shadow: $dropmenu-shadow;
      visibility: hidden;

      a {
        color: darken($light-color, 5%);
        &:hover {
          color: $nile-blue-color;
        }
      }

      ul {
        position: absolute;
        left: 100%;
        top: 0;
      }
    }

  }

  & > ul > li {
    display: inline-block;
  }

  // Animation options
  &.animated {
    ul li {
      transition: background .7s, color 0.5s;
    }

    ul li:hover > ul {
      opacity: 1;
      transform: translateY(0);
    }

    ul ul {
      transition: transform .3s, opacity .5s;
      opacity: 0;
      transform: translateY(-10px);
    }
  }
}
