body .accordion-wrapper {

    input:checked {
        & + label {
            border-bottom: 0;

            &:before {
                background-image: url(../images/icons-minus.png);
            }

            &:hover:after {
                content: none;
            }
        }

        & ~ article {
            margin: 2rem 0 2rem 9px;
        }
    }

    label {
        background: transparent;
        padding: 10px 0;
        border: 0;
        border-bottom: 1px solid #ddd;
        font-size: inherit;
        font-weight: bold;

        &:before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url(../images/icons-plus.png);
            background-size: contain;
            vertical-align: middle;
            margin-right: 10px;
        }

        &:hover {
            background: transparent;

            &:after {
                content: none;
            }
        }
    }

    article {
        border: 0;
        padding: 0 0 0 20px;
        border-left: 1px solid #ddd;

        p {
            margin: 0 0 1.5rem 0;
        }

        p:empty {
           margin: 0;
           display: none;
        }
    }

    div:last-child input:checked ~ article {
        border-bottom: 0;
    }
}
