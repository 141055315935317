%button {
    appearance: none;
    background: $primary-color;
    color: #ffffff;
    font-weight: 600;
    border: none;
    border-radius: 10rem;
    padding: 0.75rem 3.5rem;
    cursor: pointer;
    display: inline-block;
    outline: none;
    text-align: center;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &:hover {
        background: #ffffff;
        color: $primary-color;
        text-decoration: none;
    }
}

.button {
    @extend %button;

    .fa {
        font-size: $font-size + 0.5;
    }

    &.button-outline {
      background: transparent;
      color: #ffffff !important;
      border: 3px solid #ffffff;
      text-transform: uppercase;

        &:hover {
          color: transparentize(#ffffff, 0.25) !important;
          border-color: transparentize(#ffffff, 0.25);
        }
    }

    &.button-block {
        display: block;
        text-align: center;
    }
}
