.columns {
  &.padding {
    .column {
      border-right: 1.5rem solid #fff;
    }
    margin-right: -2.5rem;
  }
}

.two-columns {
  padding: 7rem 0;
  column-count: 2;

  @include breakpoint(md) {
    column-count: 1;
  }
}
