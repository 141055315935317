// Sticky Footer solution
body.footer-sticky {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .body-wrapper {
    flex: 1;
  }
}

#footer {
  .container {
    padding: 3rem auto;
  }
}
