.content-item {
  padding-bottom: 5rem;

  .date {
    color: $tertiary-color;
    font-weight: 400;
  }

  .summary {
    margin-bottom: 2rem;
    color: darken($body-font-color, 10%);
  }

  h2 a {
    color: $secondary-color;
  }

  ul {
    list-style: none;
    margin-left: 2rem;

    li {

      &:before {
        content: "\56";
        font-family: dripicons-v2;
        display: inline-block;
        margin-left: -1.3em;
        width: 1.3em;
        margin-top: -2px;
        vertical-align: middle;
      }
    }
  }
}

.news-archives {

  list-style: none;
  padding: 0;

  li {
    border-bottom: 1px solid $gray-color;
    text-align: right;
    padding: 1.5rem 0;
    margin: 0;
  }

  .active {
    a {
      color: $tertiary-color;
    }
  }
}

.item-feature {

  margin-bottom: 3rem;

  .column {
    padding: 0;
  }

  img {
    min-height:100%;
    object-fit: cover;
  }

  .column:last-child {
    border-left: 1px solid #fff;
  }
}
