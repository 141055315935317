.alert {
  padding: 0.5rem;

  p {
    margin: 0.5rem 0;
  }

  &.green {
    background: $atlantis-color;
    color: #fff;

  }

  &.red {
    background: $control-color-error;
    color: #fff;
  }
}

.list-2cols {
  .content-item {
    ul {
      column-count: 2;
    }
  }
}

.page-curl {
  position: relative;
  margin: 0 auto 3rem;
  padding: 1px;
  color: $light-color;

  &.padding {
    padding: 3rem 2rem 2rem;

    .margin-offset {
      margin: 0 -2rem -2rem -2rem;
    }
  }

  &.margin {
    margin: 5rem auto !important;
  }

  &.left .columns {
    flex-direction: row-reverse;

    .border {
      border-right: 1px solid #fff;
    }
  }

  &.right .columns {
    flex-direction: row;

    .border {
      border-left: 1px solid #fff;
    }
  }

  .columns {
    margin-left: 0;
    margin-right: 0;

    .image-bg {
      min-height: 200px;

      span {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
    .border {
      @include breakpoint(sm) {
        border: 0 !important;
      }
    }
  }

  .columns & {
    padding: 4rem 2rem 2rem;
    margin-top: 0;
  }

  h1 {
    font-size: 25px;
    font-weight: 300;

  }

  .image-bg {
    background-size: cover;
    background-position: 50% 50%;
  }

  .padding {
    padding: 4rem 3rem;
  }

  &.animated {

    &:before {
      transition: all .5s;
    }

    &:hover:before {
      border-width: 0 16px 16px 0;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 32px 32px 0;
    border-style: solid;
  }

  &.left:before {
    left: 0;
    right: inherit;
    transform: rotate(-90deg);
    -webkit-backface-visibility: hidden;

  }

  &.empty {
    background: #fff;
    border: 1px solid $empty-color;
    text-align: left;
    padding: 2rem;

    p {
      margin: 0;
    }

    &:before {
      border-color: #fff #fff $empty-color $empty-color;
      background: $empty-color;
      top: -1px;
    }
    &.left:before {
      left: -1px;
    }
    &.right:before {
      right: -1px;
    }

  }

  &.mine-shaft {
    background: $mine-shaft-color;
    a {
      color: $glacier-color;
    }

    &:before {
      border-color: #fff #fff $silver-chalice-color $silver-chalice-color;
      background: $silver-chalice-color;
    }
  }
  &.atlantis {
    background: $atlantis-color;
    a {
      color: $nile-blue-color;
    }

    &:before {
      border-color: #fff #fff $glacier-color $glacier-color;
      background: $glacier-color;
    }
  }
  &.blank {
    border-top: 1px solid $gray-color;
    color: $body-font-color;
    a {
      color: $link-color;
    }
    &:before {
      display: none;
    }
  }

  &.glacier {
    background: $glacier-color;
    a {
      color: $nile-blue-color;
    }

    &:before {
      border-color: #fff #fff $nile-blue-color $nile-blue-color;
      background: $nile-blue-color;
    }
  }

  &.nile-blue {
    background: $nile-blue-color;
    a {
      color: $atlantis-color;
    }


    &:before {
      border-color: #fff #fff $atlantis-color $atlantis-color;
      background: $atlantis-color;
    }
  }

  .owl-carousel {
    border-top: 1px solid rgba($silver-chalice-color, 0.3);
    height: 420px;
    .owl-item {

      .slide {
        position: relative;
        height: 420px;
      }

      .slide-info {
        position: absolute;
        padding: 4rem 3rem 2rem;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#000, 0.5);
        font-size: 25px;
        color: $atlantis-color;
      }


    }

    .owl-dots {
      position: absolute;
      right: 3rem;
      bottom: 1rem;

      span {
        width: 16px !important;
        height: 16px !important;

      }
    }
  }
}

.readmore {
  font-size: 15px;
  display: block;
  margin: 2rem 0 1rem;
  text-transform: uppercase;
  font-weight: 400;
  &:after {
    font-family: "dripicons-v2";
    content: 'V';
    vertical-align: middle;
  }
}

hr {
  border: 0;
  border-top: 1px solid $gray-color;
}

ul.plain {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0;
    margin: 0 0 0.5rem;
  }
}

ul li, ol li {
  line-height: 1.5;
  margin: 0;
  padding: 0.5rem 0 0.5rem 1.3rem;
}

.bg-gray {
  background: #f8f9fa;
  color: #667189;
  padding: 1rem;
}
