@font-face {
    font-family: 'Ubuntu';
    font-weight: 300;
    font-display: swap;
    font-style: normal;
    src: url("../fonts/Ubuntu-Light.woff2?v=3.19") format("woff2");
}

@font-face {
    font-family: 'Ubuntu';
    font-weight: 400;
    font-display: swap;
    font-style: normal;
    src: url("../fonts/Ubuntu-Regular.woff2?v=3.19") format("woff2");
}

@font-face {
    font-family: 'Ubuntu';
    font-weight: 500;
    font-display: swap;
    font-style: normal;
    src: url("../fonts/Ubuntu-Medium.woff2?v=3.19") format("woff2");
}